import Choices from 'choices.js';
import Observable from '../../../_assets/scripts/Observable';
import {citiesStore} from '../../../_assets/scripts/stores';

const contexts = document.querySelectorAll('[id^="calculator-context-"]');
contexts.forEach((context) => {
    const contextId = context.dataset.id;

    const calculatorController = (() => {
        const zipAndCitiesObs = new Observable([])
        const selectedCityObs = new Observable(undefined);

        citiesStore.load();

        citiesStore.data.onChange((cities) => {
            const zipAndCities = cities.map((city) => ({
                zip: city.postcode,
                cityId: city.id,
                city: city.name
            }))

            zipAndCitiesObs.setValue(zipAndCities);
        })

        return {
            zipAndCitiesObs,
            isLoadingCitiesObs: citiesStore.isLoadingObs,
            selectedCityObs
        }
    })();

    const zipSearchInput = (() => {
        const input = document.querySelector(`#calculator-zip-selector-${contextId}`);

        const citiesChoices = new Choices(input, {
            removeItemButton: false,
            removeItems: false,
            itemSelectText: '',
            placeholder: true,
            placeholderValue: 'PLZ / Wohnort',
            classNames: {
                input: "w-100"
            }
        });

        calculatorController.zipAndCitiesObs.onChange((zipAndCities) => {
            citiesChoices.setChoices(zipAndCities.map((entry) => ({
                value: entry.cityId,
                label: `${entry.zip} ${entry.city}`
            })))
        })

        const onChoice = (observer = (e) => undefined) => {
            input.addEventListener('choice', observer)
        }

        calculatorController.isLoadingCitiesObs.onChange((isLoading) => {
            if (isLoading) {
                citiesChoices.disable();
            } else {
                citiesChoices.enable();
            }
        })

        return {
            citiesChoices,
            onChoice,
        }
    })();

    const fuiPageContainer = (() => {
        const root = context.closest('.fui-page-container');

        const fieldZip = root.querySelector('[name="fields[plz]"]');
        const form = context.closest('.fui-form');

        zipSearchInput.onChoice(({detail}) => {
            const {choice} = detail;
            const cityId = choice.value;

            fieldZip.value = cityId;
            let url = new URL(form.dataset.redirect);
            url.searchParams.append('cityId', cityId);
            form.dataset.redirect = url.toString()
        });

        return {}
    })();

    const birthDateInput = (() => {
        const root = context.closest('.fui-page-container');
        const fieldBirthdate = root.querySelector('[name="fields[birthdate][datetime]"]');
        const form = context.closest('.fui-form');

        fieldBirthdate.addEventListener('change', (e) => {
            let url = new URL(form.dataset.redirect);
            const date = new Date(e.target.value).toISOString().split('T')[0]
            url.searchParams.append('birthday', date);
            form.dataset.redirect = url.toString()
        })

        return {}
    })();
});
