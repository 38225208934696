const devMode = window.sessionStorage.getItem('devMode');

if (devMode) {
    const errorFeedbackButton = (() => {
        const a = document.createElement('a');
        const debuggingInformations = document.getElementById('debugging-informations');
        const button = document.getElementById('error-feedback-button');

        button?.addEventListener('click', (e) => {
            const debugData = {
                location: window.location.href,
                userAgent: navigator.userAgent,
                entryId: debuggingInformations.dataset.entryId,
                entrySlug: debuggingInformations.dataset.entrySlug,
                entryUrl: debuggingInformations.dataset.entryUrl,
                entryType: debuggingInformations.dataset.entryType,
            };

            const body = '(Platz für Text / Screenshots)\n\n\n\n'
                + '--------------------------------------------------\n'
                + '[Debug-Informationen bitte nicht entfernen]\n\n'
                + 'Version: 1.14.4\n'
                + `URL: ${window.location.href}\n`
                + `${btoa(JSON.stringify(debugData, null, '  '))}`;
            a.href = `mailto:support@semabit.ch?body=${encodeURI(body)}`;

            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        })

        return {}
    })()
}
