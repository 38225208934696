import Choices from 'choices.js';
import Observable from '../../../_assets/scripts/Observable';
import {doctorsStore} from '../../../_assets/scripts/stores';
import Modal from 'bootstrap/js/src/modal';

const contexts = document.querySelectorAll('[id^="doctor-selector-context-"]');
contexts.forEach((context) => {
    const contextId = context.dataset.id;

    const changeModelController = (() => {
        const doctorsObs = new Observable([])
        const zipAndCitiesObs = new Observable([])
        const selectedDoctorObs = new Observable(undefined);

        doctorsStore.load();

        doctorsStore.data.onChange((doctors) => {
            const zipAndCities = doctors.map((doctor) => ({
                zip: doctor.postcode,
                city: doctor.city
            }))
            const uniqZipAndCities = Array
                .from(new Set(zipAndCities.map((entry) => entry.zip)).values())
                .sort((a, b) => a - b)
                .map((zip) => zipAndCities.find((entry) => entry.zip === zip));

            zipAndCitiesObs.setValue(uniqZipAndCities);
            doctorsObs.setValue(doctors);
        })

        return {
            doctorsObs,
            zipAndCitiesObs,
            isLoadingDoctorsObs: doctorsStore.isLoadingObs,
            selectedDoctorObs
        }
    })();

    const changeModelModal = (() => {
        const modal = document.querySelector(`#doctor-selector-modal-${contextId}`);
        const bsModal = new Modal(modal)

        const getDoctorCards = () => {
            return Array.from(modal.querySelectorAll('.doctor-card'));
        }

        return {
            element: modal,
            bsModal,
            getDoctorCards
        }
    })()

    const searchInput = (() => {
        const input = document.querySelector(`#doctor-selector-search-${contextId}`);
        const doctorSearchChoices = new Choices(input, {
            removeItemButton: false,
            removeItems: false,
            itemSelectText: '',
            placeholder: true,
            searchResultLimit: 4,
            fuseOptions: {
                threshold: 0.0,
                ignoreLocation: true
            },
        });

        changeModelController.zipAndCitiesObs.onChange((zipAndCities) => {
            doctorSearchChoices.setChoices(zipAndCities.map((entry) => ({
                value: entry.zip,
                label: `${entry.zip} ${entry.city}`
            })))
        })

        const onChoice = (observer = (e) => undefined) => {
            input.addEventListener('choice', observer)
        }

        changeModelController.isLoadingDoctorsObs.onChange((isLoading) => {
            if (isLoading) {
                doctorSearchChoices.disable();
            } else {
                doctorSearchChoices.enable();
            }
        })

        return {
            doctorSearchChoices,
            onChoice,
        }
    })();

    const SearchResultEntry = ({doctor}) => {
        const template = document.createElement('div');
        template.classList.add('doctor-card', 'col', 'd-flex', 'flex-column', 'no-link-style');

        template.innerHTML = `
        <div class="h-100 p-4 bg-white rounded-2 position-relative shadow-md">
            <div class="d-flex flex-column">
                <span id="doctor-item-title">${doctor.title}</span>
                <span id="doctor-item-name">${doctor.lastName} ${doctor.firstName} </span>
                <span id="doctor-item-address">${doctor.address}</span>
                <span id="doctor-item-city" class="fw-bold">${doctor.postcode} ${doctor.city}</span>
                <a id="doctor-item-phone">${doctor.phone}</a>
            </div>
            <div class="checked-circle border-2 border-primary-grayblue rounded-circle position-absolute"></div>
        </div>
    `;

        const [wrapper] = template.children;
        const [col, circle] = wrapper.children;

        const select = (target) => {
            const element = target || circle;
            element.classList.add('checked');
        }

        const deselect = (target) => {
            const element = target || circle;
            element.classList.remove('checked');
        }

        template.addEventListener('click', (e) => {
            const cards = changeModelModal.getDoctorCards();
            cards.forEach((card) => {
                deselect(card.querySelector('.checked-circle'));
            })

            select();
            changeModelController.selectedDoctorObs.setValue(doctor);
        })

        return {
            select,
            deselect,
            element: template
        }
    }

    const searchResults = (() => {
        const root = document.querySelector(`#doctor-selector-search-results-${contextId}`);
        const filteredDoctors = new Observable([]);

        searchInput.onChoice(({detail}) => {
            const {choice} = detail;
            const zip = choice.value;

            if (!changeModelController.isLoadingDoctorsObs.getValue()) {
                const doctors = changeModelController.doctorsObs.getValue().filter((doctor) => doctor.postcode === zip);
                filteredDoctors.setValue(doctors);
            }
        })

        filteredDoctors.onChange((doctors) => {
            root.innerHTML = '';
            root.classList.remove('d-none');

            doctors.forEach((doctor) => {
                const child = SearchResultEntry({doctor}).element;
                root.appendChild(child);
            })
        })

        return {}
    })();

    const openModalButton = (() => {
        const root = document.querySelector(`#doctor-selector-open-modal-button-${contextId}`);

        root.addEventListener('click', () => {
            changeModelModal.bsModal.show();
        })

        return {}
    })();

    const closeModalButton = (() => {
        const root = document.querySelector(`#doctor-selector-close-modal-button-${contextId}`);

        root.addEventListener('click', () => {
            changeModelModal.bsModal.hide();
        })

        return {}
    })();

    const dismissModalButton = (() => {
        const root = document.querySelector(`#doctor-selector-dismiss-modal-button-${contextId}`);

        root.addEventListener('click', () => {
            changeModelModal.bsModal.hide();
        })

        return {}
    })();

    const confirmButton = (() => {
        const root = document.querySelector(`#doctor-selector-confirm-modal-button-${contextId}`);

        const enable = () => {
            root.removeAttribute('disabled');
        }

        const disable = () => {
            root.setAttribute('disabled', '')
        }

        const onConfirm = (observer) => {
            root.addEventListener('click', (e) => {
                observer(e, changeModelController.selectedDoctorObs.getValue());
                changeModelModal.bsModal.hide();
            })
        }

        changeModelController.selectedDoctorObs.onChange((selectedDoctor) => {
            if (selectedDoctor) {
                enable();
            } else {
                disable();
            }
        })

        return {
            onConfirm
        }
    })();

    const fuiPageContainer = (() => {
        const root = context.closest('.fui-page-container');

        const fieldDoctorName = root.querySelector('[name="fields[doctor]"]');
        const fieldDoctorZsr = root.querySelector('[name="fields[doctorZsr]"]');

        confirmButton.onConfirm((e, doctor) => {
            fieldDoctorZsr.value = doctor.zsr;
            fieldDoctorName.value = [doctor.title, doctor.firstName, doctor.lastName]
                .filter(element => element !== '')
                .join(' ');
        });

        return {}
    })();

    const selectedDoctorInformation = (() => {
        const root = document.querySelector(`#doctor-selector-info-${contextId}`);
        const errorMessageField = document.querySelector(`#fui-error-fields[doctor]`);

        confirmButton.onConfirm((e, doctor) => {
            if (errorMessageField) {
                errorMessageField.classList.add('d-none');
            }

            root.innerHTML = `
            <div class="doctor-card col-12 col-md-6 col-xl-4 d-flex flex-column no-link-style mt-4">
                <div class="h-100 p-4 bg-white rounded-2 position-relative shadow-md">
                    <div class="d-flex flex-column">
                        <span id="doctor-item-title">${doctor.title}</span>
                        <span id="doctor-item-name">${doctor.lastName} ${doctor.firstName} </span>
                        <span id="doctor-item-address">${doctor.address}</span>
                        <span id="doctor-item-city" class="fw-bold">${doctor.postcode} ${doctor.city}</span>
                        <a id="doctor-item-phone">${doctor.phone}</a>
                    </div>
                </div>
            </div>
            `
        })

        return {}
    })();
})
