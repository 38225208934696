import Observable from './Observable';

export const citiesStore = (() => {
    const intranetApiUrl = window.sessionStorage.getItem('calculatorCities')
    const data = new Observable([]);
    const loading = new Observable(false);

    const load = () => {
        loading.setValue(true);
        fetch(intranetApiUrl)
            .then((response) => response.json())
            .then((cities) => data.setValue(cities))
            .finally(() => loading.setValue(false));
    };

    return {
        data,
        load,
        isLoadingObs: loading
    };
})();

export const doctorsStore = (() => {
    const intranetApiUrl = window.sessionStorage.getItem('calculatorDoctors')
    const data = new Observable([]);
    const loading = new Observable(false);

    const load = () => {
        loading.setValue(true);
        fetch(intranetApiUrl)
            .then((response) => response.json())
            .then((doctors) => data.setValue(doctors))
            .finally(() => loading.setValue(false));
    };

    return {
        data,
        load,
        isLoadingObs: loading
    };
})();
