class Observable {
    constructor(value) {
        this.value = value;
        this.observers = [];
        this.onChange = this.onChange.bind(this);
        this.setValue = this.setValue.bind(this);
        this.getValue = this.getValue.bind(this);
    }

    onChange(observer) {
        this.observers.push(observer);
    }

    setValue(value) {
        const oldValue = this.value;
        this.value = value;
        this.observers.forEach((observer) => observer(value, oldValue));
    }

    getValue() {
        return this.value;
    }
}

export default Observable;
