const devMode = window.sessionStorage.getItem('devMode');

// Lazysizes
import 'lazysizes';

// Bootstrap
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'choices.js/public/assets/scripts/choices.min';

// Custom
import './partials/header';
import './partials/error-feedback';

// Shared
import '../_shared/fast-control-icons';

document.addEventListener('lazyloaded', (e) => {
    e.target.style.backgroundColor = '';
});


