import Swiper, {Autoplay, EffectFade, Navigation, Pagination,} from 'swiper';

const sliders = document.querySelectorAll('[id^=\'aquilana-slider-\']');

const initSlider = (container, id) => {
    if (container) {
        const slides = container.querySelectorAll('.swiper-slide');

        if (!slides) {
            return;
        }

        new Swiper(container, {
            modules: [Pagination, Autoplay, EffectFade, Navigation],
            loop: true,
            pagination: {
                el: `#swiper-pagination-${id}`,
                clickable: true,
            },
            navigation: {
                nextEl: `#swiper-button-next-${id}`,
                prevEl: `#swiper-button-prev-${id}`,
            },
            slidesPerView: 1,
            spaceBetween: 0,
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    allowSlideNext: true,
                    allowSlidePrev: true,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                    allowSlideNext: true,
                    allowSlidePrev: true,
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                    allowSlideNext: false,
                    allowSlidePrev: false,
                }
            },
        });
    }
};

if (sliders?.length) {
    Array.from(sliders).forEach((slider) => {
        const container = slider.querySelector('.swiper');
        initSlider(container, slider.id);
    });
}
