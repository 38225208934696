const hideDropdowns = () => {
    document.querySelectorAll('.dropdown-menu').forEach((item) => {
        item.style.display = 'none';
    });
};

let mouseMoveTimeout = null;
document.onmousemove = async (e) => {
    if (mouseMoveTimeout) {
        clearTimeout(mouseMoveTimeout)
    }
    mouseMoveTimeout = setTimeout(() => {
        let element = e.target;

        if (element.classList.contains('dropdown-toggle-xl')) {
            let dropdown = document.querySelector(`[aria-labelledby=${element.id}]`);
            hideDropdowns();
            dropdown.style.display = 'block';
        } else if (element.classList.contains('navbar-container')
            || element.classList.contains('navbar-collapse')
            || element.classList.contains('nav-link')) {
            hideDropdowns();
            element.classList.remove('fw-bold');
        }
    }, 50)
};

document.querySelectorAll('.dropdown-menu-xl').forEach((item) => {
    item.addEventListener('mouseleave', e => {
        hideDropdowns();
    })
});

document.querySelectorAll(".dropdown-toggle-xl").forEach((item) => {
    item.addEventListener('click', e => {
        location.href = item.getAttribute('href');
    })
});

document.getElementById('navbar-button-mobile').addEventListener('click', () => {
    const body = document.querySelector('body');
    const navbar = document.getElementById('mobile-navbar');
    const button = document.getElementById('navbar-button-mobile');

    if (navbar.classList.contains('d-none')) {
        navbar.classList.remove('d-none');
        button.classList.remove('collapsed');
        body.classList.add('menu-open');
    } else {
        navbar.classList.add('d-none');
        button.classList.add('collapsed');
        body.classList.remove('menu-open');
    }
});

document.querySelectorAll('.mobile-nav-item-wrapper .accordion-button').forEach((item) => {
    const observer = new MutationObserver((mutationList) => {
        mutationList.forEach((mutation) => {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                let parentDiv = item.closest('.mobile-nav-item-wrapper');
                if (item.classList.contains('collapsed')) {
                    parentDiv.classList.remove('bg-secondary-smoke-blue');
                } else {
                    parentDiv.classList.add('bg-secondary-smoke-blue');
                }
            }
        });
    });

    observer.observe(item, {attributes: true});
})

let scrollTimeout = null;
let oldScroll = 0;
window.onscroll = () => {
    if (scrollTimeout) {
        clearTimeout(scrollTimeout)
    }

    scrollTimeout = setTimeout(() => {
        const navContainer = document.querySelector('.navbar-container');
        const navigation = document.getElementById('aquilana-navigation')

        if (oldScroll > window.scrollY) {
            if (window.scrollY > 110) {
                navigation.style.height = '80px';
                navContainer.style.height = '80px';
                navigation.classList.add('is-sticky');
            } else {
                navigation.style.height = null;
                navContainer.style.height = null;
                navigation.classList.remove('is-sticky');
            }
            navigation.style.top = '0';

        } else if (window.scrollY > 163) {
            navigation.style.top = '-130px';
        }
        oldScroll = window.scrollY;
    }, 50);
};
