if (document.getElementById('main-fast-control')) {
    document.getElementById('main-fast-control').addEventListener('click', e => {
        const controlItems = document.querySelectorAll('.fast-control-item');

        controlItems.forEach((item, index) => {
            if (item.classList.contains(`move-up-${index}`)) {
                item.classList.remove(`move-up-${index}`)
                item.classList.add(`shadow-none`)
            } else {
                item.classList.remove(`shadow-none`)
                item.classList.add(`move-up-${index}`)
            }
        })
    });
}
