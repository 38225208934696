import Carousel from 'bootstrap/js/dist/carousel';
import Modal from 'bootstrap/js/dist/modal';

const createSlides = (img, images) => {
    let markup = '';
    const currentImgSrc = img.getAttribute('data-fullsize');

    images.forEach((img) => {
        const imgSrc = img.getAttribute('data-fullsize');
        const imgAlt = img.getAttribute('alt');

        markup += `
            <div class="carousel-item${currentImgSrc === imgSrc ? ' active' : ''}">
              <img src="${imgSrc}" alt="${imgAlt}">
            </div>
        `;
    });

    return markup;
};

const createCarousel = (img, images, modalBody, uid) => {
    modalBody.innerHTML = `
    <div id="lightbox-carousel-${uid}" class="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="false">
      <div class="carousel-inner">
        ${createSlides(img, images)}
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#lightbox-carousel-${uid}" data-bs-slide="prev">
       <span class="carousel-control-prev-icon" aria-hidden="true"></span>
       <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#lightbox-carousel-${uid}" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
    `;
};

document.querySelectorAll('[id^="gallery-grid-"]').forEach((grid) => {
    const images = grid.querySelectorAll('img');
    const uid = grid.id.substring(13, grid.id.length);
    const lightboxModal = document.getElementById(`lightbox-modal-${uid}`);
    const bsModal = new Modal(lightboxModal);
    const modalBody = document.querySelector(`#lightbox-modal-${uid} .modal-body .container-fluid`);

    grid.querySelectorAll('a').forEach((link) => {
        link.addEventListener('click', (e) => {
            e.preventDefault();
            const currentImg = link.querySelector('img');
            const lightboxCarousel = document.getElementById(`lightbox-carousel-${uid}`);
            if (lightboxCarousel) {
                const parentCol = link.parentElement.parentElement;
                const index = [...parentCol.parentElement.children].indexOf(parentCol);
                const bsCarousel = new Carousel(lightboxCarousel);
                bsCarousel.to(index);
            } else {
                createCarousel(currentImg, images, modalBody, uid);
            }
            bsModal.show();
        });
    });
});
